
import { Options, Vue } from 'vue-class-component';
import { ItemType } from '@/enums/ItemType';
import router from '../router';

@Options({
  computed: {
    ItemType() {
      return ItemType;
    },
  },
  emits: ['selected-item', 'set-as-topick'],
  props: ['items', 'selectedLocationId'],
  components: {},
})
export default class ItemCardList extends Vue {
  selectedLocationId = '';
  mounted() {}

  currency(value: any) {
    return value === null ? '' : '$' + parseFloat(value).toFixed(2);
  }

  getTruncatedName(item) {
    return item.name.length > 40 ? item.name.slice(0, 40) + '...' : item.name;
  }

  getItemTypeValue(data: any) {
    if (Number(data.itemType) === ItemType.Good) {
      return 'G';
    } else if (Number(data.itemType) === ItemType.Service) {
      return 'S';
    } else if (Number(data.itemType) === ItemType.Bundle) {
      return 'B';
    }
  }

  edit(item: any) {
    if (item.itemType === ItemType.Bundle) {
      this.$router.push({
        path: `/items/${item.id}`,
        query: { bundle: 'true', locationId: this.selectedLocationId },
      });
    } else {
      router.push({
        path: `/items/${item.id}`,
        query: { locationId: this.selectedLocationId },
      });
    }
  }

  isVariable(data: any): boolean {
    const variantCount = data.variants ? data.variants.length : 0;
    if (data.bundles && data.bundles.length > 0) {
      return true;
    } else if (variantCount === 0) {
      return false;
    } else if (variantCount === 1) {
      return true;
    } else {
      return false;
    }
  }

  getPhotoSrc(item: any): string {
    if (item.hasImage && item.imageSrc) {
      return item.imageSrc;
    } else {
      return './image_placeholder.png';
    }
  }

  getSkuValue(data: any) {
    const variantCount = data.variants.length;
    if (data.bundles.length > 0) {
      return data.bundles[0].bundleSKU;
    } else if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].sku;
    } else {
      return 'Variable';
    }
  }

  getPriceValue(data: any, priceType: string) {
    const variantCount = data.variants.length;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (data.bundles && data.bundles.length > 0) {
      if (priceType === 'wholesale') {
        return '-';
      } else {
        const discount = data.bundleDiscount;
        const customPrice = Number(data.bundleCustomPrice);

        let price = 0;
        for (let i = 0; i < data.bundles.length; i++) {
          price += parseFloat(data.bundles[i].variant.price);
        }

        if (discount) {
          price = price - (price * discount) / 100;
        }

        if (customPrice) {
          return formatter.format(customPrice);
        }

        return formatter.format(price);
      }
    } else if (variantCount === 0) {
      return ' ';
    } else if (variantCount === 1) {
      if (priceType === 'wholesale') {
        if (data.itemType === ItemType.Service) {
          return '-';
        } else {
          return data.variants[0].price === null ||
            Number.isNaN(data.variants[0].wholesalePrice)
            ? '-'
            : formatter.format(data.variants[0].wholesalePrice);
        }
      } else {
        return data.variants[0].price === null ||
          Number.isNaN(data.variants[0].price)
          ? '-'
          : formatter.format(data.variants[0].price);
      }
    } else {
      return 'Variable';
    }
  }

  getTotalVariantStock(variant: any, item: any): number | string {
    let trackedVariantLocations = [];
    if (variant && variant.variantLocations) {
      trackedVariantLocations = variant.variantLocations.filter(
        (variantLocation: any) => {
          return (
            variantLocation.isActive &&
            variantLocation.isInventoryTrackingEnabled
          );
        },
      );
    }
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation: any) => {
          return variantLocation.currentStock;
        },
      );
      return stocksByLocation.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
      );
    } else return '-';
  }

  getVariantLowStock(variant: any, item: any) {
    const trackedVariantLocations = variant.variantLocations.filter(
      (variantLocation: any) => {
        return variantLocation.isActive && variantLocation.isLowStock;
      },
    );
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation: any) => {
          return variantLocation.stockThreshold;
        },
      );
      return stocksByLocation.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
      );
    } else return '-';
  }

  getTotalItemStock(item: any): number | string {
    const variantStocks: any[] = item.variants
      ? item.variants.map((variant: any) => {
          return this.getTotalVariantStock(variant, item);
        })
      : [];
    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (integerVariantStocks.length > 0) {
      return integerVariantStocks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return 'Not Tracked';
  }

  getLowItemStock(item: any): number | string {
    const variantCount = item.variants.length;
    const variantStocks: any[] = item.variants.map((variant: any) => {
      return this.getVariantLowStock(variant, item);
    });
    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (variantCount === 1) {
      if (integerVariantStocks.length > 0) {
        return integerVariantStocks.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      } else return '-';
    } else {
      return '';
    }
  }

  getUpcValue(data: any) {
    const variantCount = data.variants.length;
    if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].upc === null ? '' : `${data.variants[0].upc}`;
    }
  }
}
