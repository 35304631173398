
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import VariantFlattend from '../Interfaces/VariantFlattend';
import router from '../router';
import Variant from '@/Interfaces/Variant';
import Item from '../Interfaces/Item';
import ItemService from '@/services/ItemService';
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import { mapActions, mapGetters } from 'vuex';

@Options({
  props: ['selectTopPickItems'],
  providers: [ItemService, RoleService, LocalStorageService],
  methods: {
    ...mapActions([
      'getLocationList',
      'setSelectedLocationId',
      'setLocationOptions',
      'setProductsFilter',
      'getProducts',
    ]),
  },
  computed: mapGetters([
    'allLocationList',
    'locationOptions',
    'selectedLocationId',
    'productsFilter',
    'allProducts',
  ]),
})
export default class TopPickItems extends Vue {
  textCount = 0;
  searchTimeout: any;

  itemService = new ItemService();
  localStorageService = new LocalStorageService();
  roleService = new RoleService(new LocalStorageService());

  topPickItems: VariantFlattend[] = [];
  loading = false;
  cancelPickItems = false;
  locations: Location[] = [];
  isSuperadminLoc = false;
  selectedLocationId: any = this.localStorageService.getItem(
    'primary_location_id',
  );
  searchTerm: any = '';
  pageCount = 1;
  pageFlag = true;
  selectedItem = 0;
  locationError = false;
  showModel = false;
  history: any = [];

  activateNotification = false;
  typeNotification = 'success';
  titleNotification = '';
  messageNotification = '';

  async mounted() {
    this.$store.dispatch('getLocationList').then(() => {
      this.cancelPickItems = false;
      this.topPickItems = [];
      const locationOfUsers = this.localStorageService.getItem('locations');
      if (locationOfUsers != null && locationOfUsers != undefined) {
        const _locationOfUsers = JSON.parse(locationOfUsers);
        if (_locationOfUsers.length === 1) {
          this.selectedLocationId =
            _locationOfUsers[0].id ||
            this.localStorageService.getItem('primary_location_id');
          this.changeLocation();
        }
      }
      if (this.$route.query.locationId) {
        this.selectedLocationId =
          this.$route.query.locationId.toString() ||
          this.localStorageService.getItem('primary_location_id');
        this.getSelectedLocation();
        this.changeLocation();
      }
    });
    this.changeLocation();
    // window.addEventListener("scroll", this.handleScroll);
  }

  checkboxChanges(row: any) {
    let i = 0;
    for (; i < this.history.length; i++) {
      if (this.history[i].variantId == row.variantId) {
        this.history[i] = row;
        break;
      }
    }
    if (this.history.length === i) {
      this.history.push(row);
    }
    if (row.isTop) {
      this.selectedItem++;
    } else {
      this.selectedItem--;
    }
    this.$emit('toppicks-alert', true);
  }
  handleScroll() {
    const scrollingElement =
      document.scrollingElement || document.documentElement;
    const scrollHeight = scrollingElement.scrollTop;
    const clientHeight = scrollingElement.clientHeight;
    const scrollTop = scrollingElement.scrollHeight;
    let scrollHeightNumber = 0;
    let scrollTopNumber = 0;
    if (scrollHeight && clientHeight && scrollTop) {
      scrollHeightNumber = scrollHeight + clientHeight;
      scrollTopNumber = scrollTop;
    }
    if (this.pageFlag) {
      // if((scrollTopNumber > (scrollHeightNumber-500) && scrollTopNumber < (scrollHeightNumber-450)) && scrollHeightNumber != 0){
      if (
        scrollTopNumber < scrollHeightNumber + 10 &&
        scrollHeightNumber != 0
      ) {
        // topNeve.scrollTop = 100
        this.pageFlag = false;
        this.pageCount++;
        this.loadMore();
      }
    }
  }

  public loadMore() {
    this.pageCount++;
    this.$emit('show-loading');
    this.getSelectedLocation();
    this.findItems().then(() => {
      this.pageFlag = true;
      setTimeout(() => {
        this.$emit('hide-loading');
        this.pageFlag = true;
      }, 500);
    });
  }

  public checkedLocationId() {
    this.topPickItems = [];
    this.history = [];
    this.pageCount = 1;
    this.pageFlag = true;
    this.selectedItem = 0;
    this.locationError = false;
    this.searchTerm = '';
    this.$emit('toppicks-alert', false);
    if (this.selectedLocationId == null) {
      return;
    }

    this.$emit('show-loading');
    this.changeLocation();
  }
  public changeLocation() {
    this.$emit('show-loading');
    this.getSelectedLocation();
    this.searchTerm = '';
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    this.isSuperadminLoc = false;
    if (
      locationOfSuperadmin &&
      this.roleService.isSuperadmin &&
      locationOfSuperadmin.slice(2, -2) !== this.selectedLocationId
    ) {
      this.isSuperadminLoc = true;
    }
    this.findItems().then(() => {
      this.pageFlag = true;
      setTimeout(() => {
        this.$emit('hide-loading');
      }, 500);
    });
  }

  cahgnedVaue() {
    if (this.selectedLocationId == null) {
      this.locationError = true;
      return;
    }

    if (this.searchTerm.length <= 2 && this.searchTerm != '') {
      return false;
    }
    this.pageCount = 1;
    this.textCount++;
    this.selectedItem = 0;
    const temp = this.textCount;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.conductSearch(temp);
    }, 500);
  }

  async conductSearch(searchNumber?: number) {
    this.$emit('show-loading');
    this.selectedItem = 0;
    this.findItems(searchNumber).then(() => {
      this.pageFlag = true;
      setTimeout(() => {
        this.$emit('hide-loading');
      }, 500);
    });
  }
  public findItems(searchNumber?: number): any {
    if (searchNumber) {
      this.topPickItems = [];
    }
    return new Promise((resolved, reject) => {
      const productFilter = this.$store.getters.productsFilter;
      if (this.selectedLocationId) {
        productFilter.page = this.pageCount;
        productFilter.pageSize = 200;
        productFilter.search = this.searchTerm;
        productFilter.locationIds = this.selectedLocationId;
        this.$store.dispatch('setProductsFilter', productFilter).then(() => {
          this.$store.dispatch('getProducts').then(() => {
            if (!searchNumber || this.textCount == searchNumber) {
              this.$store.getters.allProducts.forEach(async (item: Item) => {
                if (item.variants) {
                  item.variants.forEach((variant: Variant) => {
                    for (let i = 0; i < this.history.length; i++) {
                      if (
                        this.history[i].variantLocations[0].id ==
                        variant.variantLocations[0].id
                      ) {
                        variant.variantLocations[0].isTop =
                          this.history[i].isTop;
                      }
                    }
                    if (variant.variantLocations[0].isTop) {
                      this.selectedItem++;
                    }
                    this.topPickItems.push({
                      variantId: variant.id,
                      variantName: variant.name,
                      variantSku: variant.sku,
                      itemName: item.name,
                      itemManufacturer: item.manufacturer,
                      itemId: item.id,
                      variantUpc: variant.upc,
                      variantLocations: variant.variantLocations,
                      isTop: variant.variantLocations[0].isTop,
                    });
                  });
                }
              });
              resolved(1);
            } else {
              resolved(1);
            }
          });
        });
      }
    });
  }

  private getSelectedLocation() {
    this.$store.dispatch('setSelectedLocationId', this.selectedLocationId);
    const locationId = this.selectedLocationId;
    return this.locations.filter((l: any) => l.id === locationId)[0];
  }

  async handleSubmit() {
    this.$emit('show-loading');
    for (let i = 0; i < this.history.length; i++) {
      this.history[i].variantLocations[0].isTop = this.history[i].isTop;
    }
    await this.itemService.updateVariant(this.history, this.selectedLocationId);
    this.$emit('hide-loading');
    this.activateNotification = true;
    this.typeNotification = 'success';
    this.titleNotification = 'Success';
    this.messageNotification = 'Items updated into Top pick section';

    this.$emit('change-flag', this.cancelPickItems);
  }

  hideModalConfirm() {
    this.showModel = false;
  }
  confirmApplyTaxes() {
    this.$emit('change-flag', this.cancelPickItems);
  }

  changeFlag() {
    if (this.history.length > 0) {
      this.showModel = true;
    } else {
      this.$emit('change-flag', this.cancelPickItems);
    }
  }
  clearall() {}
}
