
import { Options, Vue } from 'vue-class-component';
import { StockActionSelection } from '../enums/StockActionSelection';
import ItemService from '@/services/ItemService';
import Pagination from '@/shared/Pagination.vue';
import { ItemType } from '@/enums/ItemType';

@Options({
  components: { Pagination },
  props: ['allLocations'],
})
export default class AddInventory extends Vue {
  itemType = ItemType;
  itemService = new ItemService();
  loading = false;
  selectLocationModal = true;
  selectedLocation: any = {};
  filteredItems: any = [];
  allLocations: any = [];
  newfilteredItems: any = [];
  pageCount = 1;
  pageFlag = true;
  showConfirm = false;
  textCount = 0;
  searchTerm = '';
  searchTimeout: any;
  saveChanges = false;
  isCancelClicked = false;

  stockActionSelectedValue = '';
  deltaStockValues = {};
  deltaLowStockValues = {};
  nameDescSort = false;
  manufacturerDescSort = false;
  upcDescSort = false;
  skuDescSort = false;
  priceDescSort = false;
  wholesalepriceDescSort = false;
  quantityDescSort = false;
  totalStockDescSort = false;
  lowStockDescSort = false;

  defaultSortedName = false;
  defaultSortedManufacturer = false;
  defaultSortedSku = false;
  defaultSortedRetailPrice = false;
  defaultSortedWholesalePrice = false;
  defaultSortedTotalStock = false;
  defaultSortedLowStock = false;
  defaultSortedUpc = false;

  showDescendingName = true;
  showDescendingManufacturer = false;
  showDescendingUpc = false;
  showDescendingLowStock = false;
  showDescendingSku = false;
  showDescendingRetailPrice = false;
  showDescendingWholesalePrice = false;
  showDescendingItemType = false;
  showDescendingTotalStock = false;

  sortingValue: any = 'ASC';
  searchFieldValue: any = '';
  barcodeValue = '';
  filterFields = [];

  stockActionOptions: any = [
    { value: null, text: 'Select Stock Action' },
    { value: StockActionSelection.RECEIVED, text: 'Stock Received' },
    { value: StockActionSelection.RETURN, text: 'Restock Return' },
    { value: StockActionSelection.LOSS, text: 'Loss' },
    { value: StockActionSelection.THEFT, text: 'Theft' },
    { value: StockActionSelection.DAMAGE, text: 'Damage' },
    { value: StockActionSelection.RECOUNT, text: 'Inventory Re-count' },
  ];
  selectedStockActions: any = {};

  activateNotification = false;
  typeNotification = 'success';
  titleNotification = '';
  messageNotification = '';
  filter: any;

  async mounted() {
    this.filter = this.$store.getters.productsFilter;
    // window.addEventListener("scroll", this.handleScroll);
    this.$emit('trigger-inventory', true);
    if (this.allLocations.length === 1) {
      this.selectedLocation = this.allLocations[0];
      this.selectLocationModal = false;
      await this.locationSelected();
    }
    this.handleSearch_Field();
    document.addEventListener('keypress', this.enterEvent);
  }
  priceFormatter($event: any) {
    if (this.stockActionSelectedValue !== 'Recount') {
      if (
        ($event.keyCode < 48 || $event.keyCode > 57) &&
        $event.keyCode !== 46
      ) {
        $event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  enterEvent(e: any) {
    if (e.key !== 'Enter' && !isNaN(parseInt(e.key))) {
      this.barcodeValue = this.barcodeValue + '' + e.key;
    } else if (
      e.key === 'Enter' &&
      this.barcodeValue.length < 14 &&
      this.barcodeValue.length > 10
    ) {
      this.barcodeSearch(this.barcodeValue);
      this.barcodeValue = '';
    }
  }

  async barcodeSearch(barcode: string) {
    this.searchTerm = barcode;
    this.pageCount = 1;
    await this.conductSearch();
  }

  unmounted() {
    document.removeEventListener('keypress', this.enterEvent);
    this.$emit('trigger-inventory', false);
  }

  setup(context: any, props: any) {
    this.allLocations = props.allLocations;
  }
  async loadmore() {
    this.pageCount++;
    await this.locationSelected();
  }
  async handleScroll() {
    const scrollingElement =
      document.scrollingElement || document.documentElement;
    const scrollHeight = scrollingElement.scrollTop;
    const clientHeight = scrollingElement.clientHeight;
    const scrollTop = scrollingElement.scrollHeight;
    let scrollHeightNumber = 0;
    let scrollTopNumber = 0;
    if (scrollHeight && clientHeight && scrollTop) {
      scrollHeightNumber = scrollHeight + clientHeight;
      scrollTopNumber = scrollTop;
    }
    if (this.pageFlag) {
      if (
        scrollTopNumber < scrollHeightNumber + 10 &&
        scrollHeightNumber != 0
      ) {
        this.pageFlag = false;
        this.pageCount++;
        await this.locationSelected();
      }
    }
  }

  selectLocationModalView() {
    this.selectLocationModal = true;
  }

  hideLocationModalView() {
    this.selectLocationModal = false;
  }

  async sortCol(type: string) {
    const typeArray = type.split(' ');
    const filter = this.$store.getters.productsFilter;
    filter.sortBy = typeArray[0];
    filter.sortType = typeArray[1];
    this.filteredItems = [];
    filter.page = 1;
    this.pageCount = 1;
    switch (type) {
      case 'name DESC':
        this.nameDescSort = true;
        this.showDescendingName = true;
        this.showDescendingManufacturer = false;
        this.showDescendingUpc = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingTotalStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = false;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'manufacturer DESC':
        this.manufacturerDescSort = true;
        this.showDescendingManufacturer = true;
        this.showDescendingRetailPrice = false;
        this.showDescendingName = false;
        this.showDescendingUpc = false;
        this.showDescendingSku = false;
        this.showDescendingTotalStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = false;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'upc DESC':
        this.upcDescSort = true;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingUpc = true;
        this.showDescendingSku = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = false;
        break;
      case 'lowStock DESC':
        this.lowStockDescSort = true;
        this.showDescendingLowStock = true;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingUpc = false;
        this.showDescendingSku = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = false;
        this.defaultSortedUpc = true;
        break;
      case 'sku DESC':
        this.skuDescSort = true;
        this.showDescendingSku = true;
        this.showDescendingName = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = false;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'price DESC':
        this.priceDescSort = true;
        this.showDescendingRetailPrice = true;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = false;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'wholesalePrice DESC':
        this.wholesalepriceDescSort = true;
        this.showDescendingWholesalePrice = true;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = false;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'totalStock DESC':
        this.totalStockDescSort = true;
        this.showDescendingTotalStock = true;
        this.showDescendingWholesalePrice = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = false;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'itemType DESC':
        this.showDescendingItemType = true;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      default:
        this.nameDescSort = false;
        this.priceDescSort = false;
        this.manufacturerDescSort = false;
        this.skuDescSort = false;
        this.upcDescSort = false;
        this.lowStockDescSort = false;
        this.wholesalepriceDescSort = false;
        this.totalStockDescSort = false;
        break;
    }
    await this.getAllRecords();
  }
  async getAllRecords() {
    this.filter = {
      ...this.filter,
      page: this.pageCount,
      itemType: 1,
      search: this.searchTerm,
      searchField: '',
      locationId: this.selectedLocation.id,
      lowStock: 0,
      outofStock: 0,
      sortBy: '',
      sortType: '',
    };

    this.$store.commit('setProductsFilter', {
      locationId: this.selectedLocation.id,
    });
    this.loading = true;
    await this.$store.dispatch('getProducts');
    const dataResult = this.$store.getters.allProducts;
    this.filteredItems = dataResult;
    this.loading = false;
  }
  async handleSearch_Field(event?: any, para?: string) {
    if (event) {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if (event.target.value.trim() === '') {
        this.filterFields = this.filterFields.filter(
          (item: any) => item.searchField !== para,
        );
      } else {
        const filterObject = {
          search:
            event.target.value.trim() === ''
              ? this.searchTerm
              : event.target.value,
          searchField: para,
        };
        this.filterFields.push(filterObject);
      }
      this.searchTimeout = setTimeout(() => {
        this.searchFieldValue = event.target.value;
        this.filteredItems = [];
        this.filter.search = event.target.value;
        this.filter.page = 1;
        this.filter.pageSize = 15;
        this.filter.searchArray = this.filterFields;
        this.$store.dispatch('setProductsFilter', this.filter).then(() => {
          this.$store.dispatch('getProducts').then(() => {
            this.filteredItems = this.$store.getters.allProducts;
            this.loading = false;
            if (this.filteredItems.length < this.filter.totalCount) {
              this.pageFlag = true;
            }
          });
        });
      }, 1000);
    } else {
      this.searchFieldValue = '';
      this.filteredItems = [];
      this.filter.search = '';
      this.filter.page = 1;
      this.filter.pageSize = 15;
      this.filter.searchArray = this.filterFields;
      this.$store.dispatch('setProductsFilter', this.filter).then(() => {
        this.$store.dispatch('getProducts').then(() => {
          this.filteredItems = this.$store.getters.allProducts;
          this.loading = false;
          if (this.filteredItems.length < this.filter.totalCount) {
            this.pageFlag = true;
          }
        });
      });
    }
  }
  async locationSelected() {
    this.loading = true;
    this.selectLocationModal = false;
    this.filter.page = this.pageCount;
    this.filter.pageSize = 15;
    this.filter.itemType = 1;
    this.filter.search = this.searchTerm;
    this.filter.searchField = '';
    this.filter.location = this.selectedLocation.id;
    this.filter.lowStock = 0;
    this.filter.outofStock = 0;
    this.filter.sortBy = '';
    this.filter.sortType = '';
    this.filter.pageType = 'update_inventory';
    await this.$store.dispatch('setProductsFilter', this.filter).then(() => {
      this.$store.dispatch('getProducts').then(() => {
        this.loading = false;
        this.filteredItems = this.$store.getters.allProducts;
        if (this.filteredItems.length < this.filter.totalCount) {
          this.pageFlag = true;
        }
        this.filter = this.$store.getters.productsFilter;
      });
    });
  }

  isDisabled(variant: any, index: number, vindex?: number) {
    let isDisabledflag = true;
    this.filteredItems[index].variants[0].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          if (variantLocation.mostRecentStockAction) {
            isDisabledflag = false;
          }
        }
      },
    );
    return isDisabledflag;
  }

  isDisabledVariants(variant: any, index: number, vindex: number) {
    let isDisabledflag = true;
    this.filteredItems[index].variants[vindex].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          if (variantLocation.mostRecentStockAction) {
            isDisabledflag = false;
          }
        }
      },
    );
    return isDisabledflag;
  }
  changeStockAction(variant: any, index: number, event: any) {
    this.stockActionSelectedValue = event.target.value;
    this.deltaStockValues = {};
    this.saveChanges = true;
    this.$emit('changes-made', true);
    this.filteredItems[index].variants[0].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          variantLocation.updateStock = 0;
          if (variantLocation.oldStock || variantLocation.oldStock == 0) {
            variantLocation.currentStock = variantLocation.oldStock;
          } else {
            variantLocation.oldStock = variantLocation.currentStock;
          }
          variantLocation.mostRecentStockAction = event.target.value;
          variantLocation.calculationStockAction = '';
        }
      },
    );
  }

  variantChangeStockAction(
    variant: any,
    index: number,
    vindex: number,
    event: any,
  ) {
    this.filteredItems[index].variants[vindex].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          variantLocation.updateStock = 0;
          if (variantLocation.oldStock || variantLocation.oldStock == 0) {
            variantLocation.currentStock = variantLocation.oldStock;
          } else {
            variantLocation.oldStock = variantLocation.currentStock;
          }
          variantLocation.mostRecentStockAction = event.target.value;
          variantLocation.calculationStockAction = '';
        }
      },
    );
  }

  addStockToVariant(variant: any, index: number, event: any) {
    this.filteredItems[index].variants[0].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          if (
            variantLocation.mostRecentStockAction === 'Received' ||
            variantLocation.mostRecentStockAction === 'Restocked'
          ) {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock =
              Number(variantLocation.oldStock) +
              Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` ${Number(
              variantLocation.oldStock,
            )} + ${Number(variantLocation.updateStock)} = `;
            variant.stockUpdated = true;
          } else if (
            variantLocation.mostRecentStockAction === 'Loss' ||
            variantLocation.mostRecentStockAction === 'Theft' ||
            variantLocation.mostRecentStockAction === 'Damage'
          ) {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock =
              Number(variantLocation.oldStock) -
              Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` ${Number(
              variantLocation.oldStock,
            )} - ${Number(variantLocation.updateStock)} = `;
            variant.stockUpdated = true;
          } else if (variantLocation.mostRecentStockAction === 'Recount') {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock = Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` Was: ${Number(
              variantLocation.updateStock,
            )} = `;
            variant.stockUpdated = true;
          }
        }
      },
    );
  }

  addStockToVariantByIndex(
    variant: any,
    index: number,
    vindex: number,
    event: any,
  ) {
    this.filteredItems[index].variants[vindex].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          if (
            variantLocation.mostRecentStockAction === 'Received' ||
            variantLocation.mostRecentStockAction === 'Restocked'
          ) {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock =
              Number(variantLocation.oldStock) +
              Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` ${Number(
              variantLocation.oldStock,
            )} + ${Number(variantLocation.updateStock)} = `;
            variant.stockUpdated = true;
          } else if (
            variantLocation.mostRecentStockAction === 'Loss' ||
            variantLocation.mostRecentStockAction === 'Theft' ||
            variantLocation.mostRecentStockAction === 'Damage'
          ) {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock =
              Number(variantLocation.oldStock) -
              Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` ${Number(
              variantLocation.oldStock,
            )} - ${Number(variantLocation.updateStock)} = `;
            variant.stockUpdated = true;
          } else if (variantLocation.mostRecentStockAction === 'Recount') {
            variantLocation.updateStock = Number(event.target.value);
            variantLocation.currentStock = Number(variantLocation.updateStock);
            variantLocation.calculationStockAction = ` Was: ${Number(
              variantLocation.updateStock,
            )} = `;
            variant.stockUpdated = true;
          }
        }
      },
    );
  }

  addLowStockToVariant(variant: any, index: number, event: any) {
    this.filteredItems[index].variants[0].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          variantLocation.stockThreshold = Number(event.target.value);
          variantLocation.isLowStock = true;
          variant.stockThresholdUpdated = true;
        }
      },
    );
  }

  addLowStockToVariantByIndex(
    variant: any,
    index: number,
    vindex: number,
    event: any,
  ) {
    this.filteredItems[index].variants[vindex].variantLocations.forEach(
      (variantLocation: any) => {
        if (variantLocation.location.id === this.selectedLocation.id) {
          variantLocation.stockThreshold = Number(event.target.value);
          variantLocation.isLowStock = true;
          variant.stockThresholdUpdated = true;
        }
      },
    );
  }

  changeFlag() {
    if (this.saveChanges) {
      this.showConfirm = true;
    } else {
      this.$emit('change-flag-add-inventory', false);
    }
    this.searchFieldValue = '';
    this.filteredItems = [];
    this.filter.search = '';
    this.filter.page = 1;
    this.filter.pageSize = 15;
    this.filter.searchArray = [];
    this.filter.pageType = '';
    this.$store.dispatch('setProductsFilter', this.filter).then(() => {
      this.$store.dispatch('getProducts').then(() => {
        this.filteredItems = this.$store.getters.allProducts;
        this.loading = false;
        if (this.filteredItems.length < this.filter.totalCount) {
          this.pageFlag = true;
        }
      });
    });
  }
  positiveButton() {
    this.showConfirm = false;
    if (this.saveChanges && !this.isCancelClicked) {
      this.saveChanges = false;
      this.isCancelClicked = false;
      this.searchTerm = '';
      this.conductSearch();
      return;
    }
    this.$emit('change-flag-add-inventory', false);
  }
  negativeButton() {
    this.showConfirm = false;
    this.isCancelClicked = false;
  }

  getSkuValue(data: any) {
    const variantCount = data.variants.length;
    const bundleCount = data.bundles.length;
    if (bundleCount > 0) {
      return data.bundles[0].bundleSKU;
    } else if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].sku;
    } else if (variantCount > 1 && data.itemType === ItemType.Good) {
      return '-';
    } else {
      return 'Variable';
    }
  }

  getUpcValue(data: any) {
    const variantCount = data.variants.length;

    if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].upc === null ? '' : `${data.variants[0].upc}`;
    }
  }

  getPriceUnformattedValue(data: any, priceType: string) {
    const variantCount = data.variants.length;
    if (data.bundles && data.bundles.length > 0) {
      if (priceType === 'wholesale') {
        return '-';
      } else {
        let price = 0;
        for (let i = 0; i < data.bundles.length; i++) {
          price += parseFloat(data.bundles[i].variant.price);
        }
        return price;
      }
    } else if (variantCount === 0) {
      return ' ';
    } else if (variantCount === 1) {
      if (priceType === 'wholesale') {
        if (data.itemType === ItemType.Service) {
          return '-';
        } else {
          return data.variants[0].price === null ||
            Number.isNaN(data.variants[0].wholesalePrice)
            ? '-'
            : Number(data.variants[0].wholesalePrice);
        }
      } else {
        return data.variants[0].price === null ||
          Number.isNaN(data.variants[0].price)
          ? '-'
          : Number(data.variants[0].price);
      }
    } else if (data.itemType === ItemType.Good) {
      return '-';
    } else {
      return 'Variable';
    }
  }
  getPriceValue(data: any, priceType: string) {
    const variantCount = data.variants.length;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (data.bundles && data.bundles.length > 0) {
      if (priceType === 'wholesale') {
        return '-';
      } else {
        let price = 0;
        for (let i = 0; i < data.bundles.length; i++) {
          price += parseFloat(data.bundles[i].variant.price);
        }
        return formatter.format(price);
      }
    } else if (variantCount === 0) {
      return ' ';
    } else if (variantCount === 1) {
      if (priceType === 'wholesale') {
        if (data.itemType === ItemType.Service) {
          return '-';
        } else {
          return data.variants[0].price === null ||
            Number.isNaN(data.variants[0].wholesalePrice)
            ? '-'
            : formatter.format(data.variants[0].wholesalePrice);
        }
      } else {
        return data.variants[0].price === null ||
          Number.isNaN(data.variants[0].price)
          ? '-'
          : formatter.format(data.variants[0].price);
      }
    } else if (variantCount > 1 && data.itemType === ItemType.Good) {
      //sum wholesale price of each variant
      return '-';
    } else {
      return 'Variable';
    }
  }

  getVariantStockActionFroLocation(variant: any) {
    const locationId = this.selectedLocation.id;
    const variantStock = variant.variantLocations.filter((varLoc: any) => {
      if (varLoc.location.id === locationId) {
        return varLoc;
      }
    });

    if (variantStock[0] && variantStock[0].calculationStockAction) {
      return variantStock[0].calculationStockAction;
    } else {
      return '';
    }
  }

  getVariantStockFroLocation(variant: any, item?: any) {
    const locationId = this.selectedLocation.id;
    if (item) {
      const variantStocks: any[] = item.variants.map((variant: any) => {
        return this.getTotalVariantStock(variant, item);
      });
      if (
        item.itemType === ItemType.Bundle &&
        variantStocks[0] == 'Not Tracked'
      ) {
        return variantStocks[0];
      }
      const integerVariantStocks: number[] = variantStocks.filter((stock) => {
        return Number.isInteger(stock);
      });
      if (integerVariantStocks.length > 0) {
        return integerVariantStocks.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      } else return 'Not Tracked';
    }
    const variantStock = variant.variantLocations.filter((varLoc: any) => {
      if (varLoc.location.id === locationId) {
        return varLoc;
      }
    });
    return variantStock[0] ? variantStock[0].currentStock : 0;
  }

  getLowStockTracked(variant: any) {
    const isLowStock = variant.variantLocations.some((varLoc: any) => {
      if (varLoc.location.id === this.selectedLocation.id) {
        return varLoc.isLowStock;
      }
    });

    return isLowStock;
  }

  getVariantLowStockFroLocation(variant: any) {
    const locationId = this.selectedLocation.id;
    const variantStock = variant.variantLocations.filter((varLoc: any) => {
      if (varLoc.location.id === locationId) {
        return varLoc;
      }
    });
    return variantStock[0] ? variantStock[0].stockThreshold : 0;
  }

  getTotalVariantStock(variant: any, item: any): number | string {
    let trackedVariantLocations: any = [];
    if (variant && variant.variantLocations) {
      trackedVariantLocations = variant.variantLocations.filter(
        (variantLocation: any) => {
          return (
            variantLocation.isActive &&
            variantLocation.isInventoryTrackingEnabled
          );
        },
      );
    }
    if (item.itemType === ItemType.Bundle) {
      if (item.bundle_least_stock != undefined) {
        return item.bundle_least_stock.least_stock != undefined ||
          item.bundle_least_stock.least_stock >= 0
          ? item.bundle_least_stock.least_stock
          : item.bundle_least_stock;
      } else if (item.bundles?.length == 0) {
        return '-';
      }
    }
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation: any) => {
          return variantLocation.currentStock;
        },
      );
      return stocksByLocation.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
      );
    } else return '-';
  }

  showChild(data: any) {
    if (data.variants.length > 1) {
      data.showVariants = !data.showVariants;
    }
  }

  async saveAddInventory() {
    this.loading = true;
    const updatedItems = this.filteredItems.filter((item: any) => {
      if (item.stockUpdated || item.stockThresholdUpdated) {
        return item;
      }
    });
    let valudation = false;
    for (let i = 0; i < this.filteredItems.length; i++) {
      for (let j = 0; j < this.filteredItems[i].variants.length; j++) {
        for (
          let k = 0;
          k < this.filteredItems[i].variants[j].variantLocations.length;
          k++
        ) {
          if (
            this.filteredItems[i].variants[j].variantLocations[k]
              .mostRecentStockAction
          ) {
            this.filteredItems[i].variants[j].variantLocations[k].errorMsg = '';
            if (
              this.filteredItems[i].variants[j].variantLocations[k]
                .updateStock == 0
            ) {
              this.filteredItems[i].variants[j].variantLocations[k].errorMsg =
                'Please Add Value';
              valudation = true;
            }
          }
        }
      }
    }
    if (valudation) {
      this.loading = false;
      return;
    }

    const item_res = null;
    let resp;
    try {
      resp = await this.itemService.updateAllItem(
        updatedItems,
        this.selectedLocation.id,
      );
      if (resp.status === 200) {
        this.loading = false;
        this.$emit('change-flag-add-inventory', false);
        this.saveChanges = false;
        this.$emit('changes-made', false);
      }
    } catch (e) {
      this.saveChanges = false;

      this.activateNotification = true;
      this.typeNotification = 'error';
      this.messageNotification = 'Error while Update Stocks';
    }
    this.loading = false;
  }
  changedValue() {
    if (this.saveChanges) {
      this.showConfirm = true;
    } else {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageCount = 1;
        this.conductSearch();
        this.getAllRecords();
      }, 1000);
    }
  }

  async conductSearch() {
    this.loading = true;
    this.selectLocationModal = false;
    this.filter = {
      ...this.filter,
      page: this.pageCount,
      itemType: 1,
      search: this.searchTerm,
      searchField: '',
      locationId: this.selectedLocation.id,
      lowStock: 0,
      outofStock: 0,
      sortBy: '',
      sortType: '',
    };
    await this.$store.dispatch('setProductsFilter', this.filter).then(() => {
      this.$store.dispatch('getProducts').then(() => {
        this.loading = false;
        this.filteredItems = this.$store.getters.allProducts;

        if (this.filteredItems.length < this.filter.totalCount) {
          this.pageFlag = true;
        }
      });
    });
  }

  pageEvent({ page }) {
    this.pageCount = page;
    this.$store.commit('setProductsFilter', { page });
    // this.conductSearch();
    this.getAllRecords();
  }

  setPageSize(pageSize: number) {
    this.filter.pageSize = pageSize;
    (this.filter.page = 1),
      this.$store.commit('setProductsFilter', { page: 1, pageSize });
    // this.conductSearch();
    this.getAllRecords();
  }
}
