import ItemService from '@/services/ItemService';

const itemService = new ItemService();
// General methods to parse information
export const chunkArray = (myArray: unknown[], chunkSize: number) => {
  const results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }
  return results;
};

const typeOfCategory = (category: string) => {
  if (category === 'goods' || category === 'good') {
    return 1;
  }

  if (category === 'service' || category === 'services') {
    return 2;
  }

  return 0;
};

const parseVariantName = (
  variantName: string,
  itemName: string,
  itemType: number,
) => {
  if (variantName === '') {
    return itemName;
  }

  if (variantName.length > 0 && itemType === 1) {
    return variantName;
  }

  return itemName;
};

export const isNullOrUndefinedValue = (value: unknown) => {
  if (value === null || value === '' || typeof value === undefined) {
    return true;
  }
  return false;
};

// Make validations according to data

const variantAttributeValueAlreadyExistsOnFile = (
  value: any,
  fieldName: string,
  csvData: any[],
) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  let counter = 0;
  csvData.forEach((element) => {
    const item = element[0];
    item.variants.forEach((variant) => {
      if (value.length > 0) {
        for (const [key, variantValue] of Object.entries(variant)) {
          if (key === fieldName && variantValue === value) {
            counter++;
            if (counter > 1) {
              break;
            }
          }
        }
      }
    });
  });

  if (counter > 1) {
    return true;
  }

  return false;
};

const itemNameAlreadyExists = (value: string, csvData: any[]) => {
  let counter = 0;
  csvData.forEach((element) => {
    const item = element[0];
    if (item.name.length > 0 && item.name === value) {
      counter++;
    }
  });

  if (counter > 1) {
    return true;
  }

  return false;
};

const validateUPC = async (
  value: any,
  parentIndex: number,
  index: number,
  fieldName: string,
  locationId: string,
  csvData: any[],
) => {
  let upcIsValid = true;
  const upcLength = value !== undefined ? value.length : 0;

  if ((upcLength >= 1 && upcLength < 12) || upcLength > 13) {
    upcIsValid = false;
  }

  if (isNaN(value) && upcLength > 0) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: 'UPC variant must be numeric.',
    };
  }

  if (!upcIsValid) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: 'UPC variant must have between 12 and 13 digits.',
    };
  }

  if (variantAttributeValueAlreadyExistsOnFile(value, fieldName, csvData)) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: 'UPC is duplicated in your file.',
    };
  }

  if (upcLength >= 12 && upcLength <= 13) {
    const upcAlreadyExist = await itemService.validateVariantUpc(
      1,
      1,
      value,
      locationId,
    );
    if (upcAlreadyExist.data.items.length > 0) {
      return {
        fieldName,
        index,
        error: true,
        parentIndex,
        currentValue: value,
        message: 'UPC already exists.',
      };
    }
  }

  return {
    fieldName,
    index,
    error: false,
    parentIndex,
    currentValue: value,
    message: '',
  };
};

const validateQuantity = (
  field: unknown,
  value: unknown,
  parentIndex: number,
  index: number,
  fieldName: string,
) => {
  const quantity = Number(value);
  if (isNaN(quantity) && value !== undefined) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: `${field} must be numeric.`,
    };
  }

  if (quantity < 0 && value !== undefined) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: `${field} must be equal to or greater than zero.`,
    };
  }

  return {
    fieldName,
    index,
    error: false,
    parentIndex,
    currentValue: value,
    message: '',
  };
};

const validatePrice = (
  field: unknown,
  value: unknown,
  parentIndex: number,
  index: number,
  fieldName: string,
) => {
  const price = Number(value);

  if (isNaN(price) && value != undefined) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: `${field} must be numeric.`,
    };
  }

  if (price < 0) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: `${field} equal to or greater than zero.`,
    };
  }

  return {
    fieldName,
    index,
    parentIndex,
    error: false,
    currentValue: value,
    message: '',
  };
};

const validateItemCategory = (
  value: number,
  index: number,
  fieldName: string,
  variants: Record<string, unknown>[],
) => {
  if (value < 1 || value > 2) {
    return {
      fieldName,
      index,
      error: true,
      currentValue: value,
      message: 'Item category must be Goods or Services.',
    };
  }

  if (value === 1 && variants.length === 0) {
    return {
      fieldName,
      index,
      error: true,
      currentValue: value,
      message: 'Goods category must have at least one variant.',
    };
  }

  if (value === 2 && variants.length === 0) {
    return {
      fieldName: 'price',
      index: 0,
      parentIndex: index,
      error: true,
      currentValue: '',
      message: 'Price is required.',
    };
  }

  return {
    fieldName,
    index,
    error: false,
    currentValue: value,
    message: '',
  };
};

const validateVariantName = (
  value: any,
  parentIndex: number,
  index: number,
  fieldName: string,
  csvData: any[],
) => {
  if (value.length == 0) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: `Variant name is required.`,
    };
  }

  if (variantAttributeValueAlreadyExistsOnFile(value, fieldName, csvData)) {
    return {
      fieldName,
      index,
      error: true,
      parentIndex,
      currentValue: value,
      message: 'Variant name is duplicated in your file.',
    };
  }

  return {
    fieldName,
    index,
    parentIndex,
    error: false,
    currentValue: value,
    message: '',
  };
};

const validateName = (
  value: any,
  index: number,
  fieldName: string,
  csvData: any[],
) => {
  if (value.length === 0) {
    return {
      fieldName,
      index,
      error: true,
      currentValue: value,
      message: 'Name is required.',
    };
  }

  if (itemNameAlreadyExists(value, csvData)) {
    return {
      fieldName,
      index,
      error: true,
      currentValue: value,
      message: 'Item name is duplicated in your file.',
    };
  }

  return {
    fieldName,
    index,
    error: false,
    currentValue: value,
    message: '',
  };
};

const validateProducts = async (data: any) => {
  const errors = [];

  for (let index = 0; index < data.length; index++) {
    const item = data[index][0];

    errors.push(
      validateItemCategory(item.itemType, index, 'itemType', item.variants),
    );
    errors.push(validateName(item.name, index, 'name', data));

    for (let v = 0; v < item.variants.length; v++) {
      const value = item.variants[v];
      errors.push(
        await validateUPC(
          value.upc,
          index,
          v,
          'upc',
          item.locations[0].id,
          data,
        ),
      );
      errors.push(validateVariantName(value.name, index, v, 'name', data));
      errors.push(validatePrice('Price', value.price, index, v, 'price'));
      errors.push(
        validatePrice(
          'Wholesale Price',
          value.wholesalePrice,
          index,
          v,
          'wholesalePrice',
        ),
      );
      errors.push(
        validateQuantity('Stock', value.quantity, index, v, 'quantity'),
      );

      value.variantLocations.forEach((value) => {
        errors.push(
          validateQuantity(
            'Low Stock Alert',
            value.stockThreshold,
            index,
            v,
            'stockThreshold',
          ),
        );
      });
    }
  }

  return [...new Set(errors)];
};

// Parse products imported
export const parseProductsImported = async (
  selectedLocations: string[],
  csvData: any[],
) => {
  try {
    const productArr = [];

    for (let index = 1; index < csvData.length; index++) {
      const row = csvData[index];
      productArr.push(
        row.reduce(function (result, field, index1) {
          result[csvData[0][index1]] = field;
          return result;
        }, {}),
      );
    }
    const finalArr = productArr.map((item) => {
      const Obj = Object.keys(item);
      const ObjLength = (Obj.length - 4) / 6;
      const newItem: Record<string, any> = {};
      newItem.variants = [];
      newItem.itemType = typeOfCategory(item['Category'].toLowerCase());
      newItem.name = item['Item Name'];

      if (newItem.name || newItem.name === '') {
        newItem.description = item['Item Description'];
        newItem.manufacturer = item['Manufacturer name'];

        for (let i = 0; i < ObjLength; i++) {
          if (newItem.itemType === 2 && i >= 1) {
            break;
          }

          if (
            item['Variant' + (i + 1) + ' Price'] == undefined ||
            item['Variant' + (i + 1) + ' Price'] == ''
          ) {
            break;
          }

          const variantName = parseVariantName(
            item['Variant' + (i + 1) + ' Name'],
            item['Item Name'],
            newItem.itemType,
          );
          const price = isNullOrUndefinedValue(
            item['Variant' + (i + 1) + ' Price'],
          )
            ? 0
            : item['Variant' + (i + 1) + ' Price'].replace('$', '');
          const wholesalePrice = isNullOrUndefinedValue(
            item['Variant' + (i + 1) + ' Wholesale Price'],
          )
            ? 0
            : item['Variant' + (i + 1) + ' Wholesale Price'].replace('$', '');
          const upc = isNullOrUndefinedValue(item['Variant' + (i + 1) + ' UPC'])
            ? ''
            : item['Variant' + (i + 1) + ' UPC'];
          const sku = isNullOrUndefinedValue(item['Variant' + (i + 1) + ' SKU'])
            ? ''
            : item['Variant' + (i + 1) + ' SKU'];
          const quantity = isNullOrUndefinedValue(
            item['Variant' + (i + 1) + ' Stock'],
          )
            ? 0
            : item['Variant' + (i + 1) + ' Stock'];

          const variant: any = {
            name: variantName,
            sku,
            price,
            upc,
            quantity,
            wholesalePrice,
          };

          if (variant.name) {
            variant.variantLocations = [];

            for (let j = 0; j < selectedLocations.length; j++) {
              const location = selectedLocations[j];
              const currentStock = isNullOrUndefinedValue(
                item['Variant' + (i + 1) + ' Stock'],
              )
                ? 0
                : item['Variant' + (i + 1) + ' Stock'];
              const isLowStock = isNullOrUndefinedValue(
                item['Variant' + (i + 1) + ' Low Stock Alert'],
              )
                ? true
                : item['Variant' + (i + 1) + ' Low Stock Alert'] > 0;
              const stockThreshold = isNullOrUndefinedValue(
                item['Variant' + (i + 1) + ' Low Stock Alert'],
              )
                ? 0
                : item['Variant' + (i + 1) + ' Low Stock Alert'];

              variant.variantLocations.push({
                location: location,
                isInventoryTrackingEnabled: quantity > 0,
                currentStock,
                isActive: true,
                untouched: true,
                isLowStock,
                stockThreshold,
              });
            }

            newItem.variants.push(variant);
          }
        }
        newItem.locations = selectedLocations;
        return newItem;
      }
    });

    const data = chunkArray(finalArr, 1);
    const errors = await validateProducts(data);
    const hasActiveErrors =
      errors.find((element) => element.error === true) !== undefined;

    return {
      data,
      errors,
      formatFileError: false,
      hasActiveErrors,
    };
  } catch (e) {
    console.error('error: ', e);
    return {
      data: [],
      errors: [],
      formatFileError: true,
      hasActiveErrors: true,
    };
  }
};

export const CsvHeader =
  'Manufacturer name,Item Name,Item Description,Category,Variant1 Name,Variant1 SKU,Variant1 Price,Variant1 Wholesale Price,Variant1 UPC,Variant1 Stock,Variant1 Low Stock Alert,Variant2 Name,Variant2 SKU,Variant2 Price,Variant2 Wholesale Price,Variant2 UPC,Variant2 Stock,Variant2 Low Stock Alert,Variant3 Name,Variant3 SKU,Variant3 Price,Variant3 Wholesale Price,Variant3 UPC,Variant3 Stock,Variant3 Low Stock Alert\n';

export const CsvData = [
  [
    'Zen',
    'Ruby1',
    'D1',
    'Good',
    'Mark1',
    465461,
    15,
    10,
    '5322071405321',
    25,
    15,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    'Zen',
    'Ruby2',
    'D2',
    'Good',
    'Mark2',
    4654651,
    25.25,
    24.2,
    '5322071405202',
    100,
    20,
    'Mark3',
    155456525,
    32.25,
    30.1,
    '5322071415212',
    '',
    '',
    'Mark4',
    4546865,
    39.99,
    29.0,
    '522207040522',
    150,
    20,
  ],
  [
    '',
    'Ruby3',
    'D3',
    'Good',
    'Mark5',
    564848489,
    11.78,
    11,
    '5465513620320',
    15,
    12,
    'Mark6',
    4645135,
    15,
    14.9,
    '5095513620320',
    152,
    15,
    'Mark8',
    513513,
    21,
    25.0,
    '5035113620120',
    10,
    15,
  ],
  [
    '',
    'Ruby4',
    'D4',
    'Service',
    '',
    '',
    450,
    11,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    '',
    'Ruby5',
    'D5',
    'Service',
    '',
    '',
    540,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    'Dr Opala',
    'Talcum',
    'D7',
    'Goods',
    'Lily',
    4678465,
    15,
    13,
    '5322071405322',
    32,
    12,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    '',
    'Import Accupressure',
    'D6',
    'Services',
    '',
    '',
    999,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    'Dr Marcus',
    'Red6',
    '',
    'Goods',
    'Mark9',
    '',
    '$20',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    'Dr Marcus',
    'Red7',
    '',
    'Goods',
    'Mark10',
    '',
    '$56',
    '',
    '',
    '',
    '',
    'Mark11',
    '',
    '$65',
    '',
    '',
    '',
    '',
    'Mark12',
    '',
    75,
    '',
    '',
    '',
    '',
  ],
];
