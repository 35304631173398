
import Item from '@/Interfaces/Item';
import ImportError from '@/Interfaces/ImportError';
import { PropType, reactive, toRefs } from 'vue';

export default {
  name: 'PreviewItemsImported',
  props: {
    products: {
      type: Array as PropType<Array<Item>>,
      required: true,
    },
    errors: {
      type: Array as PropType<Array<ImportError>>,
      required: true,
    },
  },
  setup(props, context) {
    // Vars
    const data = reactive({});

    //-- Methods
    const findError = (
      index: number,
      fieldName: string,
      parentIndex = 0,
      isChild = false,
    ): ImportError => {
      const error = props.errors.filter((element: ImportError) => {
        if (isChild) {
          if (
            element.index === index &&
            element.parentIndex === parentIndex &&
            element.fieldName === fieldName
          ) {
            return element;
          }
        } else {
          if (element.index === index && element.fieldName === fieldName) {
            return element;
          }
        }
      })[0];

      if (error != undefined) {
        return error;
      }

      return {
        fieldName,
        index,
        error: false,
        parentIndex,
        currentValue: '',
        message: '',
      };
    };

    const findVariantError = (index: number): boolean => {
      const error = props.errors.filter((error: ImportError) => {
        if (error.parentIndex === index && error.error) {
          return error;
        }
      });

      if (error.length > 0) {
        return true;
      }

      return false;
    };

    return {
      ...toRefs(data),
      findError,
      findVariantError,
    };
  },
};
