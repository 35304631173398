
import { Options, Vue } from 'vue-class-component';
import router from '@/router';

import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import { ItemType } from '@/enums/ItemType';
import ItemExportService from '@/services/ItemExportService';
import GenericSortService from '@/services/GenericSortService';
import SearchService from '@/services/SearchService';
import ItemCardList from '@/components/ItemCardList.vue';
import TopPickItems from '@/components/TopPickItems.vue';
import ImportProductsModal from '@/components/ImportProductsModal.vue';
import { mapActions, mapGetters } from 'vuex';
import AddInventory from '@/components/AddInventoryItemList.vue';

import ItemService from '@/services/ItemService';

import { formatCurrency } from '@/helper/validations';
// @ts-ignore
import Item from '@/Interfaces/Item';
import ProgressBar from '@/shared/ProgressBar.vue';
import ModalContainer from '@/shared/ModalContainer.vue';

import VariantFlattend from '@/Interfaces/VariantFlattend';
import IconBoxArrowInDown from '@/shared/BoxArrowInDown.vue';
import IconBoxArrowUp from '@/shared/IconBoxArrowUp.vue';
import Pagination from '@/shared/Pagination.vue';

@Options({
  props: ['variant'],
  components: {
    ItemCardList,
    TopPickItems,
    ImportProductsModal,
    AddInventory,
    ProgressBar,
    ModalContainer,
    IconBoxArrowInDown,
    IconBoxArrowUp,
    Pagination,
  },
  filters: {
    currency(value: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
  methods: {
    ...mapActions(['getProducts', 'setProductsFilter']),
  },
  computed: mapGetters(['allProducts', 'productsFilter']),
})
export default class Items extends Vue {
  itemType = ItemType;
  totalCount = 0;
  textCount = 0;
  madeChanges = false;
  addInventoryItemTrigger = false;
  searchTimeout: any;
  roleService = new RoleService(new LocalStorageService());
  localStorageService = new LocalStorageService();
  itemExportService = new ItemExportService();
  itemSortService = new GenericSortService();
  searchService = new SearchService();
  itemService = new ItemService();
  noData = true;
  products: any[] = [];
  items: any[] = [];
  filteredItems: Array<any> = [];
  lfilteredItems = [];
  tfilteredItems = [];
  ofilteredItems = [];
  lsfilteredItems = [];
  selectTopPickItems = [];
  locationOfUser: any = {};
  showUploadCSV = false;
  totalUploadCSV = 0;
  completeUploadCSV = 0;
  statusUploadCSV = 'In Progress';
  activateNotification = false;
  typeNotification: 'success' | 'error' = 'success';
  titleNotification = '';
  messageNotification = '';
  user = {
    id: '',
    username: '',
    email: '',
    password: '',
    role: {
      id: '',
      name: '',
    },
  };
  openedDetailed: any = [];
  searchTerm = '';
  showImportModel = false;
  showInventoryView = false;
  filterByLowStock = false;
  filterByZeroStock = false;

  defaultSortedName = false;
  defaultSortedManufacturer = false;
  defaultSortedSku = false;
  defaultSortedRetailPrice = false;
  defaultSortedWholesalePrice = false;
  defaultSortedItemType = false;
  defaultSortedTotalStock = false;
  defaultSortedLowStock = false;
  defaultSortedUpc = false;

  nameDescSort = false;
  manufacturerDescSort = false;
  itemTypeDescSort = false;
  priceDescSort = false;
  wholesalepriceDescSort = false;
  totalStockDescSort = false;
  lowCostDescSort = false;
  lowStockDescSort = false;
  showDescendingName = true;
  showDescendingManufacturer = false;
  showDescendingUpc = false;
  showDescendingLowStock = false;
  showDescendingSku = false;
  showDescendingRetailPrice = false;
  showDescendingWholesalePrice = false;
  showDescendingItemType = false;
  showDescendingTotalStock = false;

  skuDescSort = false;
  upcDescSort = false;
  locationDescSort = false;

  loading = false;
  pageView = false;
  barcodeValue = '';
  pageViewForTopPick = false;
  loadmoreflag = true;
  public role = '';
  locs: any = [];
  locations: any = [];
  csvData: any = [];
  locationOptions: any = [];
  selectedLocationId: any = this.localStorageService.getItem(
    'primary_location_id',
  );

  page = 1;
  pageSize = 15;
  previous_Next_Disable = true;
  Next_Btn_Disable = false;
  lastPageResponse = 0;
  firstPagination = 1;
  secoundPagination = 2;
  thirdPagination = 3;
  sortingValue: any = 'ASC';
  searchFieldValue: any = '';
  sortByCol: any = [
    { value: null, text: 'Please Select Column' },
    { value: 'name ASC', text: 'Name Ascending' },
    { value: 'name DESC', text: 'Name Descending' },
    { value: 'price ASC', text: 'Price Ascending' },
    { value: 'price DESC', text: 'Price Descending' },
    { value: 'itemType ASC', text: 'Item Type Ascending' },
    { value: 'itemType DESC', text: 'Item Type Descending' },
  ];
  filterType: any = [
    { value: null, text: 'Please Select Type' },
    { value: '1', text: 'Goods' },
    { value: '2', text: 'Services' },
    { value: '3', text: 'Bundle' },
    { value: '4', text: 'Favorites' },
  ];
  selectedCol: any = null;
  filterByType: any = null;

  // Confirmation Modal
  alertTitle = '';
  alertMessage = '';
  showConfirm = false;
  negativeBtnText;
  positiveBtnText: 'Ok' | 'Yes' = 'Yes';

  toppicksalert = false;
  action: 'delete' | undefined;
  selectedProducts: Array<any> = [];
  progressbarTitle = '';
  filterFields = [];

  get disableActions() {
    return this.filteredItems.length
      ? this.filteredItems.some((item) => item.selected)
      : false;
  }

  get selectAllCheckbox() {
    return this.filteredItems.length
      ? this.filteredItems.every((item) => item.selected)
      : false;
  }

  getSkuValue(data: any) {
    let variantCount = 0;
    let bundleCount = 0;

    if (data.variants) {
      variantCount = data.variants.length;
    }
    if (data.bundles) {
      bundleCount = data.bundles.length;
    }

    if (data.itemType === ItemType.Service) {
      return '-';
    }

    if (bundleCount > 0 && data.itemType === ItemType.Bundle) {
      return data.bundles[0].bundleSKU;
    } else if (variantCount === 1) {
      return data.variants[0].sku;
    } else if (variantCount > 1) {
      return '-';
    } else {
      return '';
    }
  }

  topiksAlert(value: boolean) {
    this.toppicksalert = value;
  }
  changesMade(changesMade: boolean) {
    this.madeChanges = changesMade;
  }
  addInventoryPageTriggered(value: boolean) {
    this.addInventoryItemTrigger = value;
  }
  showLoading() {
    this.loading = true;
  }
  hideLoading() {
    this.loading = false;
  }
  async downloadCsv(items: any) {
    this.loading = true;
    const filter = this.$store.getters.productsFilter;
    const new_filter = filter;
    new_filter.page = 0;
    new_filter.pageSize = 500;
    let dataResult: any = [];
    for (let index = 0; index < 10; index++) {
      new_filter.page++;
      await this.$store
        .dispatch('setProductsFilter', new_filter)
        .then(async () => {});
      await this.$store.dispatch('getProducts').then(() => {});
      const products = this.$store.getters.allProducts;
      if (products.length === 0) break;
      dataResult = [...dataResult, ...products];
    }
    await this.$store.dispatch('setProductsFilter', filter);
    const data: any = this.itemExportService.getCSVValues(dataResult);
    const name: string = this.itemExportService.getCsvFilename('products');
    let csv: string = '"' + Object.keys(data[0]).join('","') + '"\n';
    data.forEach(function (row: any) {
      const values = Object.values(row);
      for (let index = 0; index < values.length; index++) {
        const str = values[index];
        values[index] = JSON.stringify(str).replace(/[\W_]+/g, ' ');
      }
      const str = values.join('","');
      csv += '"' + str;
      csv += '"\n';
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    hiddenElement.click();
    this.loading = false;
  }

  sortItems() {
    this.items.sort((a: any, b: any) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      else return 0;
    });
  }

  sortPriceValue(data: any) {
    let variantCount = 0;
    let bundleCount = 0;

    if (data.variants) {
      variantCount = data.variants.length;
    }
    if (data.bundles) {
      bundleCount = data.bundles.length;
    }

    if (bundleCount > 0) {
      let price = 0;
      for (let i = 0; i < data.bundles.length; i++) {
        price += parseFloat(
          data.bundles[i].variant.price.toString().replace('$', ''),
        );
      }
      return price;
    } else if (variantCount === 0) {
      return 0;
    } else if (variantCount === 1) {
      let price = 0;

      if (data && data.variants[0] && data.variants[0].price) {
        price = data.variants[0].price;
      }

      return parseFloat(price.toString().replace('$', ''));
    } else {
      let price = 0;
      data.variants.forEach((element: any) => {
        price += parseFloat(element.price.toString().replace('$', ''));
      });
      return price;
    }
  }

  sortPrice(a: any, b: any, isAsc: boolean) {
    let formattedA: any = parseFloat(
      this.getPriceValue(a, 'price').slice(1, -1),
    );
    let formattedB: any = parseFloat(
      this.getPriceValue(b, 'price').slice(1, -1),
    );
    formattedA = Number.isNaN(formattedA)
      ? Number.POSITIVE_INFINITY
      : formattedA;
    formattedB = Number.isNaN(formattedB)
      ? Number.POSITIVE_INFINITY
      : formattedB;
    return this.itemSortService.sortGeneric(formattedA, formattedB, isAsc);
  }

  sortSku(a: any, b: any, isAsc: boolean) {
    const formattedA = this.getSkuValue(a);
    const formattedB = this.getSkuValue(b);
    return this.itemSortService.sortGeneric(formattedA, formattedB, isAsc);
  }

  sortUpc(a: any, b: any, isAsc: boolean) {
    const formattedA = this.getUpcValue(a);
    const formattedB = this.getUpcValue(b);
    return this.itemSortService.sortGeneric(formattedA, formattedB, isAsc);
  }

  sortLowStock(a: any, b: any, isAsc: boolean) {
    let formattedA: any = this.getLowItemStock(a);
    let formattedB: any = this.getLowItemStock(b);
    if (typeof formattedA === 'string') formattedA = null;
    if (typeof formattedB === 'string') formattedB = null;
    return this.itemSortService.sortGeneric(formattedA, formattedB, isAsc);
  }

  getItemTypeValue(data: any) {
    if (data.itemType === ItemType.Good) {
      return 'Goods';
    } else if (data.itemType === ItemType.Service) {
      return 'Services';
    } else if (data.itemType === ItemType.Bundle) {
      return 'Bundle';
    }
  }

  handleFilterByLowStock() {
    const filter = this.$store.getters.productsFilter;
    filter.page = 1;
    this.$store.commit('setProductsFilter', filter);
    this.filterByLowStock = !this.filterByLowStock;
    this.conductSearch();
  }

  handleFilterByZeroStock() {
    const filter = this.$store.getters.productsFilter;
    filter.page = 1;
    this.$store.commit('setProductsFilter', filter);
    this.filterByZeroStock = !this.filterByZeroStock;
    this.conductSearch();
  }

  public resetSearch() {
    this.searchTerm = '';
    this.filterByLowStock = false;
    this.filterByZeroStock = false;
    this.conductSearch();
  }

  private outOfStockCondition(resultData: any) {
    const items = resultData.filter((item: any) => {
      let flag = false;
      let variantsLength = 0;

      if (item.variants) {
        variantsLength = item.variants.length;
      }

      for (let i = 0; i < variantsLength; i++) {
        for (let j = 0; j < item.variants[i].variantLocations.length; j++) {
          const row = item.variants[i].variantLocations[j];
          if (row.isInventoryTrackingEnabled && row.currentStock == 0) {
            flag = true;
          }
        }
      }
      return flag;
    });
    return items;
  }

  private lowStockCondition(resultData: any) {
    const items = resultData.filter((item: any) => {
      let flag = false;
      let variantsLength = 0;

      if (item.variants) {
        variantsLength = item.variants.length;
      }

      for (let i = 0; i < variantsLength; i++) {
        for (let j = 0; j < item.variants[i].variantLocations.length; j++) {
          const row = item.variants[i].variantLocations[j];
          if (
            row.isInventoryTrackingEnabled &&
            row.isLowStock &&
            row.currentStock <= row.currentStock &&
            row.currentStock != 0
          ) {
            flag = true;
          }
        }
      }
      return flag;
    });
    return items;
  }

  async getAllRecords(flag?: any, barcode?: any, searchNumber?: number) {
    this.$store.commit('setProductsFilter', {
      locationId: this.selectedLocationId,
      lowStock: +this.filterByLowStock,
      outofStock: +this.filterByZeroStock,
      itemType:
        this.filterByType && this.filterByType < 4 ? this.filterByType : 0,
      locationIds: this.selectedLocationId,
      topPicks: +(this.filterByType == 4),
    });

    this.loading = true;
    await this.$store.dispatch('getProducts');
    this.filteredItems = this.$store.getters.allProducts;

    if (
      this.searchTerm.length <= 13 &&
      this.searchTerm.length >= 12 &&
      !isNaN(Number(this.searchTerm))
    ) {
      const setBarcode = this.searchTerm;

      if (this.filteredItems.length !== 0) {
        this.noData = false;
      } else {
        this.noData = true;
      }

      if (this.filteredItems.length === 1 && !this.showInventoryView) {
        this.barcodeValue = '';
        this.$router.push(
          '/items/' +
            this.filteredItems[0].id +
            `?locationId=${this.selectedLocationId}`,
        );
      }

      if (this.filteredItems.length === 0) {
        this.alertTitle = 'alert';
        this.alertMessage = 'No product found with upc ' + setBarcode;
        this.positiveBtnText = 'Ok';
        this.showConfirm = true;
        this.resetSearch();
      }
    }

    const filter = this.$store.getters.productsFilter;
    this.page = filter.page;
    this.pageSize = filter.pageSize;
    this.totalCount = filter.totalCount;

    this.loading = false;
    this.lastPageResponse = this.$store.getters.productsFilter.lastPage;
    this.totalCount = this.$store.getters.productsFilter.totalCount;

    if (this.selectedProducts.length) {
      this.selectedProducts.map((prod) => {
        const idx = this.filteredItems.findIndex((i) => i.id == prod.id);
        if (idx >= 0) {
          this.filteredItems[idx].selected = true;
        }
      });
    }
  }

  changedValue() {
    this.textCount++;
    const temp = this.textCount;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.conductSearch('', temp);
    }, 1000);
  }

  filterItemsByType() {
    const filter = this.$store.getters.productsFilter;
    filter.page = 1;
    filter.pageSize = this.pageSize;
    filter.search = this.searchTerm;
    this.$store.dispatch('setProductsFilter', filter).then(() => {
      this.getAllRecords();
    });
  }

  conductSearch(barcode = '', searchNumber?: number) {
    this.loading = true;
    this.filteredItems = [];
    if (barcode) {
      this.getAllRecords(1, this.searchTerm);
    } else {
      this.$store.commit('setProductsFilter', { search: this.searchTerm });
      this.getAllRecords(undefined, '', searchNumber);
    }
  }

  getPriceValue(data: any, priceType: string) {
    if (
      data.variants &&
      data.variants.length &&
      data.variants.length > 1 &&
      data.dataType == 1
    ) {
      return '-';
    }

    let variantCount = 0;

    if (data.variants) {
      variantCount = data.variants.length;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (
      data.bundles &&
      data.bundles.length > 0 &&
      data.itemType === ItemType.Bundle
    ) {
      if (priceType === 'wholesale') {
        return '-';
      } else {
        const discount = data.bundleDiscount;
        const customPrice = Number(data.bundleCustomPrice);

        let price = 0;
        for (let i = 0; i < data.bundles.length; i++) {
          price += parseFloat(data.bundles[i].variant.price);
        }

        if (discount) {
          price = price - (price * discount) / 100;
        }

        if (customPrice) {
          return formatter.format(customPrice);
        }

        return formatter.format(price);
      }
    } else if (variantCount === 0) {
      return ' ';
    } else if (variantCount === 1) {
      if (priceType === 'wholesale') {
        if (data.itemType === ItemType.Service) {
          return '-';
        } else {
          return data.variants[0].price === null ||
            Number.isNaN(data.variants[0].wholesalePrice)
            ? '-'
            : formatter.format(data.variants[0].wholesalePrice);
        }
      } else {
        return data.variants[0].price === null ||
          Number.isNaN(data.variants[0].price)
          ? '-'
          : formatter.format(data.variants[0].price);
      }
    } else {
      return '-';
    }
  }

  getTotalVariantStock(variant: any, item: any): number | string {
    let trackedVariantLocations: any = [];
    if (variant && variant.variantLocations) {
      trackedVariantLocations = variant.variantLocations.filter(
        (variantLocation: any) => {
          return (
            variantLocation.isActive &&
            variantLocation.isInventoryTrackingEnabled
          );
        },
      );
    }
    if (item.itemType === ItemType.Bundle) {
      if (item.bundle_least_stock != undefined) {
        return item.bundle_least_stock.hasInventoryTrackingEnabled
          ? item.bundle_least_stock.least_stock
          : 'Not Tracked';
      } else if (item.bundles && item.bundles.length == 0) {
        return '-';
      }
    }
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation: any) => {
          return variantLocation.currentStock;
        },
      );
      return stocksByLocation.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
      );
    } else return '-';
  }

  getVariantLowStock(variant: any, item: any) {
    let trackedVariantLocations: any = [];
    if (variant && variant.variantLocations) {
      trackedVariantLocations = variant.variantLocations.filter(
        (variantLocation: any) => {
          return variantLocation.isActive && variantLocation.isLowStock;
        },
      );
    }
    if (item.itemType === ItemType.Bundle) return '-';
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation: any) => {
          return variantLocation.stockThreshold;
        },
      );
      return stocksByLocation.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
      );
    } else return '-';
  }

  getTotalItemStock(item: any): number | string {
    if (item.itemType === ItemType.Service) {
      return '-';
    }

    if (
      item.variants &&
      item.variants.length &&
      item.variants.length > 1 &&
      item.itemType === ItemType.Good
    ) {
      return '-';
    }

    if (item.itemType === ItemType.Bundle) {
      if (item.bundles && item.bundles.length >= 1) {
        return item.bundle_least_stock.hasInventoryTrackingEnabled
          ? item.bundle_least_stock.least_stock
          : 'Not Tracked';
      }
    }

    let variantStocks = [];

    if (item.variants) {
      variantStocks = item.variants.map((variant: any) => {
        return this.getTotalVariantStock(variant, item);
      });
    }

    if (
      item.itemType === ItemType.Bundle &&
      variantStocks[0] &&
      variantStocks[0] == 'Not Tracked'
    ) {
      return variantStocks[0];
    }
    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (integerVariantStocks.length > 0) {
      return integerVariantStocks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return 'Not Tracked';
  }

  getLowItemStock(item: any): number | string {
    if (
      item.variants &&
      item.variants.length &&
      item.variants.length > 1 &&
      item.itemType == ItemType.Good
    ) {
      return '-';
    }

    if (item.itemType === ItemType.Bundle) {
      if (item.bundles && item.bundles.length === 1) {
        return item.bundles[0].variant.variantLocations[0].isLowStock
          ? item.bundles[0].variant.variantLocations[0].stockThreshold
          : 'Not Tracked';
      }
    }

    let isLowStock = false;

    if (item && Array.isArray(item.variants) && item.variants.length > 0) {
      isLowStock = item.variants[0].variantLocations.some((varLoc: any) => {
        if (varLoc.location.id === this.selectedLocationId) {
          return varLoc.isLowStock;
        }
      });
    }

    if (!isLowStock) {
      return 'Not Tracked';
    }

    if (
      item.bundles &&
      item.bundles.length &&
      item.bundles.length > 1 &&
      item.itemType === ItemType.Bundle
    ) {
      return 'variable';
    }

    let variantCount = 0;

    if (item.variants) {
      variantCount = item.variants.length;
    }

    let variantStocks = [];

    if (item.variants) {
      variantStocks = item.variants.map((variant: any) => {
        return this.getVariantLowStock(variant, item);
      });
    }

    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (variantCount === 1) {
      if (integerVariantStocks.length > 0) {
        return integerVariantStocks.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      } else return '-';
    } else {
      return '';
    }
  }

  getUpcValue(data: any) {
    let variantCount = 0;

    if (data.variants) {
      variantCount = data.variants.length;
    }

    if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].upc === null ? '' : `${data.variants[0].upc}`;
    }
  }

  expandRow(row: any) {
    if (this.openedDetailed.includes(row.id)) {
      this.openedDetailed = this.openedDetailed.filter(
        (openedRow: string) => openedRow !== row.id,
      );
    } else {
      this.openedDetailed.push(row.id);
    }
  }

  async mounted() {
    this.loading = true;
    this.pageViewForTopPick = false;

    const filter = this.$store.getters.productsFilter;
    this.filteredItems = [];
    filter.searchArray = [];
    this.$store.commit('setProductsFilter', filter);

    let userLocation = this.localStorageService.getItem('locations');
    userLocation = JSON.parse(userLocation);
    await this.$store.dispatch('getLocationList');
    this.locs = this.$store.getters.allLocationList;
    this.setLocationOption();
    if (!this.roleService.isSupport && !this.roleService.isSuperadmin) {
      const filter = this.$store.getters.productsFilter;
      filter.locationId = '';

      this.pageView = false;

      this.locationOfUser = this.locs.filter((l: any) =>
        userLocation.includes(l.id),
      );
    } else {
      this.locationOfUser = this.locs.filter((l: any) =>
        userLocation.includes(l.id),
      );
      this.loading = false;
    }
    this.selectTopPickItems = [];

    /**
     * Lets set the selected id if exists in state
     */
    // this.selectedLocationId = this.$store.getters.getCurrentLocation
    /**
     * If location is already present call change location
     */
    if (this.selectedLocationId) {
      await this.changeLocation();
    }
  }

  enterEvent(e: any) {
    const value = e.target.value;

    if (this.selectedLocationId) {
      if (this.$route.name === 'items') {
        if (e.key !== 'Enter' && !isNaN(parseInt(e.key))) {
          this.barcodeValue = value + '' + e.key;
        } else if (
          e.key === 'Enter' &&
          value.length <= 13 &&
          value.length >= 12 &&
          !isNaN(Number(value))
        ) {
          this.barcodeSearch(value);
          this.barcodeValue = '';
        }
      }
    }
  }

  async barcodeSearch(barcode: string) {
    this.searchTerm = barcode;
    this.conductSearch(barcode);
  }

  async changeLocation() {
    const vm = this;
    this.loading = true;
    this.filteredItems = [];
    this.selectedProducts = [];
    this.selectedCol = null;
    this.filterByType = null;
    this.filterByLowStock = false;
    this.filterByZeroStock = false;
    this.searchTerm = '';
    if (this.selectedLocationId) {
      this.localStorageService.store(
        'primary_location_id',
        this.selectedLocationId,
      );
      const filter = this.$store.getters.productsFilter;
      filter.page = 1;
      filter.pageSize = this.pageSize;
      filter.search = this.searchTerm;
      filter.locationId = this.selectedLocationId;
      filter.location = '';
      filter.locationIds = '';
      this.$store.dispatch('setProductsFilter', filter).then(() => {
        this.getAllRecords();
        const focusHolder: any = vm.$refs.focusHolder;
        focusHolder.focus();
      });
    }
    this.loading = false;
  }

  selectedItemHandler(record: any) {
    if (record.itemType === ItemType.Bundle) {
      router.push({
        path: `items/${record.id}`,
        query: { bundle: 'true', locationId: this.selectedLocationId },
      });
    } else {
      router.push({
        path: `items/${record.id}`,
        query: { locationId: this.selectedLocationId },
      });
    }
  }

  chunkArray(myArray: any, chunk_size: any) {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  validateValue(value: string): boolean {
    if (value === null || value === '' || typeof value === undefined) {
      return true;
    }
    return false;
  }

  /**
   *  Validate if UPC Variant has between 12 and 13 digits
   */
  checkUpcValidation(itemsArr: any[]): boolean {
    const mergeItems = itemsArr
      .reduce((arr: any, e: any) => {
        return arr.concat(e);
      })
      .map((item: any) => item.variants)
      .reduce((arr: any, e: any) => {
        return arr.concat(e);
      });

    const variants = mergeItems.filter((value: any) => {
      const upcLength = value.upc !== undefined ? value.upc.length : 0;
      if ((upcLength >= 1 && upcLength < 12) || upcLength > 13) {
        return value;
      }
    });

    return variants.length > 0 ? false : true;
  }

  async importProducts(csvData: Item[]) {
    this.progressbarTitle = 'Import Product Status';
    try {
      this.showImportModel = false;
      this.loading = true;
      this.showUploadCSV = true;
      this.totalUploadCSV = csvData.length;
      this.completeUploadCSV = 0;
      this.statusUploadCSV = 'In Progress';

      // IVI-265: Added promise to reload the items after importing the items
      const itemsBulk = [];
      csvData.forEach((items) => {
        itemsBulk.push(items[0]);
        this.completeUploadCSV++;
      });

      if (itemsBulk.length > 0) {
        const resp = await this.itemService.importItems(
          itemsBulk,
          this.selectedLocationId,
        );

        if (resp.status === 200) {
          if (this.totalUploadCSV === this.completeUploadCSV) {
            this.showImportModel = false;
            setTimeout(() => {
              this.resetUploadValues();
              this.showUploadCSV = false;
              this.alertTitle = 'success';
              this.alertMessage = 'Items imported successfully!';
              this.showConfirm = true;
            }, 1000);
          }
        } else {
          this.completeUploadCSV++;
          this.alertTitle = 'alert';
          this.alertMessage = 'Error while importing csv';
          this.showConfirm = true;
        }
      } else {
        this.completeUploadCSV++;
        this.alertTitle = 'alert';
        this.alertMessage = 'No items to import!';
        this.showConfirm = true;
      }

      this.showUploadCSV = false;
      this.statusUploadCSV = 'Completed';
      this.loading = false;
      this.filteredItems = [];
      this.getAllRecords();
    } catch (error) {
      this.showUploadCSV = false;
      this.completeUploadCSV++;
      this.alertTitle = 'alert';
      this.alertMessage = 'Error while importing csv';
      this.showConfirm = true;
      this.loading = false;
    }
  }

  resetUploadValues() {
    this.completeUploadCSV = 0;
    this.totalUploadCSV = 0;
  }

  getPriceVal(id: any) {
    if (id === null) {
      return 0.0;
    } else {
      return id;
    }
  }

  async changeView() {
    if (this.pageView) {
      this.pageView = false;
    } else {
      this.pageView = true;
    }
  }

  getPhotoSrc(variant: any) {
    if (variant.itemHasImage && variant.itemImageSrc) {
      return variant.itemImageSrc;
    } else {
      return '/image_placeholder.png';
    }
  }

  selectTopPicks() {
    this.pageViewForTopPick = true;
  }

  changeFlag(cancelAddmyloc: any): void {
    this.pageViewForTopPick = cancelAddmyloc;
  }

  async changeFlagAddInventory(cancelAddInventory: boolean) {
    this.madeChanges = false;
    this.showInventoryView = cancelAddInventory;
    this.searchTerm = '';
    this.selectedCol = null;
    this.filterByType = null;
    this.filterByLowStock = false;
    this.filterByZeroStock = false;
    const filter = this.$store.getters.productsFilter;
    filter.page = 1;
    filter.pageSize = this.pageSize;
    filter.search = '';
    filter.searchField = '';
    filter.locationId = '';
    filter.itemType = 0;
    filter.lowStock = 0;
    filter.outofStock = 0;
    filter.sortBy = '';
    filter.sortType = '';
    filter.pageType = '';
    filter.searchArray = [];
    this.$store.dispatch('setProductsFilter', filter).then(() => {
      this.getAllRecords();
      this.$forceUpdate();
    });
  }

  private setLocationOption() {
    this.locationOptions = this.locationOptions.concat(
      this.locs.map((location: any) => {
        return { value: location.id, text: location.name };
      }),
    );
  }

  async sortCol(type: string) {
    const typeArray = type.split(' ');
    const filter = this.$store.getters.productsFilter;
    filter.sortBy = typeArray[0];
    filter.sortType = typeArray[1];
    this.filteredItems = [];
    filter.page = 1;

    switch (type) {
      case 'name DESC':
        this.nameDescSort = true;
        this.showDescendingName = true;
        this.showDescendingManufacturer = false;
        this.showDescendingUpc = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingTotalStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = false;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'manufacturer DESC':
        this.manufacturerDescSort = true;
        this.showDescendingManufacturer = true;
        this.showDescendingRetailPrice = false;
        this.showDescendingName = false;
        this.showDescendingUpc = false;
        this.showDescendingSku = false;
        this.showDescendingTotalStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = false;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'upc DESC':
        this.upcDescSort = true;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingUpc = true;
        this.showDescendingSku = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingLowStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = false;
        break;
      case 'stockThreshold DESC':
        this.lowStockDescSort = true;
        this.showDescendingLowStock = true;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingUpc = false;
        this.showDescendingSku = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = false;
        this.defaultSortedUpc = true;
        break;
      case 'sku DESC':
        this.skuDescSort = true;
        this.showDescendingSku = true;
        this.showDescendingName = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = false;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'price DESC':
        this.priceDescSort = true;
        this.showDescendingRetailPrice = true;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingWholesalePrice = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = false;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'wholesalePrice DESC':
        this.wholesalepriceDescSort = true;
        this.showDescendingWholesalePrice = true;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.showDescendingTotalStock = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = false;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'currentStock DESC':
        this.totalStockDescSort = true;
        this.showDescendingTotalStock = true;
        this.showDescendingWholesalePrice = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.showDescendingItemType = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = true;
        this.defaultSortedTotalStock = false;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      case 'itemType DESC':
        this.itemTypeDescSort = true;
        this.showDescendingItemType = true;
        this.showDescendingWholesalePrice = false;
        this.showDescendingTotalStock = false;
        this.showDescendingRetailPrice = false;
        this.showDescendingSku = false;
        this.showDescendingName = false;
        this.showDescendingManufacturer = false;
        this.showDescendingLowStock = false;
        this.showDescendingUpc = false;
        this.defaultSortedName = true;
        this.defaultSortedManufacturer = true;
        this.defaultSortedSku = true;
        this.defaultSortedRetailPrice = true;
        this.defaultSortedWholesalePrice = true;
        this.defaultSortedItemType = false;
        this.defaultSortedTotalStock = true;
        this.defaultSortedLowStock = true;
        this.defaultSortedUpc = true;
        break;
      default:
        this.nameDescSort = false;
        this.priceDescSort = false;
        this.manufacturerDescSort = false;
        this.skuDescSort = false;
        this.upcDescSort = false;
        this.lowStockDescSort = false;
        this.wholesalepriceDescSort = false;
        this.itemTypeDescSort = false;
        this.totalStockDescSort = false;
        break;
    }
    this.locationDescSort = type === 'location DESC' ? true : false;
    this.$store.commit('setProductsFilter', filter);
    await this.getAllRecords();
  }

  showChild(data: any) {
    let variantsLength = 0;

    if (data.variants) {
      variantsLength = data.variants.length;
    }

    if (variantsLength > 1 && data.itemType === ItemType.Good) {
      data.showVariants = !data.showVariants;
    } else if (data.bundles.length > 1 && data.itemType === ItemType.Bundle) {
      data.showVariants = !data.showVariants;
    }
  }

  async handleSearch_Field(event: any, para: string) {
    this.textCount++;
    const temp = this.textCount;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    if (event.target.value.trim() === '') {
      this.filterFields = this.filterFields.filter(
        (item: any) => item.searchField !== para,
      );
    } else {
      const filterObject = {
        search:
          event.target.value.trim() === ''
            ? this.searchTerm
            : event.target.value,
        searchField: para,
      };
      this.filterFields.push(filterObject);
    }

    this.searchTimeout = setTimeout(() => {
      const filter = this.$store.getters.productsFilter;
      this.filteredItems = [];
      filter.page = 1;
      this.searchTerm;
      filter.pageSize = this.pageSize;
      filter.searchArray = this.filterFields;
      this.$store.commit('setProductsFilter', filter);
      this.getAllRecords(undefined, '', temp);
    }, 1000);
  }
  showImportModal() {
    this.showImportModel = true;
  }

  showInventory() {
    this.showInventoryView = true;
  }

  hideImportModal() {
    this.showImportModel = false;
  }

  confirmActionHandler() {
    if (this.action === 'delete') {
      this.action = undefined;
      this.deleteSelectedProducts();
    }
    this.showConfirm = false;
  }

  negativeActionHandler() {
    this.showConfirm = false;
  }

  edit(item: any) {
    if (item.itemType === ItemType.Bundle) {
      this.$router.push({
        path: `/items/${item.id}`,
        query: { bundle: 'true', locationId: this.selectedLocationId },
      });
    } else {
      router.push({
        path: `/items/${item.id}`,
        query: { locationId: this.selectedLocationId },
      });
    }
  }

  selectAllProductsHandler(event: any) {
    const checkbox: HTMLInputElement = event.target;
    this.filteredItems.forEach((i) => (i.selected = checkbox.checked));
  }

  deleteProductsConfirmation() {
    if (!this.filteredItems.some((i) => i.selected)) {
      return;
    }
    const countProducts = this.filteredItems.filter(
      (i) => i.selected && i.itemType % 2 !== 0,
    ).length;
    const countServices = this.filteredItems.filter(
      (i) => i.selected && i.itemType === ItemType.Service,
    ).length;
    this.alertTitle = 'attention';
    this.negativeBtnText = 'Cancel';
    this.positiveBtnText = 'Yes';
    this.alertMessage = `You are about to permanently delete 
      ${countProducts ? countProducts + ' products' : ''} 
      ${countServices && countProducts ? ' and/or ' : ''} 
      ${countServices ? countServices + ' services' : ''}. 
      Are you sure you would like to proceed?`;
    this.showConfirm = true;
    this.action = 'delete';
  }

  formatCurrencyValue(value: any) {
    return formatCurrency(value);
  }

  async deleteSelectedProducts() {
    this.progressbarTitle = 'Deleting products in progress';
    this.selectedProducts = this.filteredItems.filter((i) => i.selected);
    const total = this.selectedProducts.length;
    if (!total) {
      return;
    }
    this.loading = true;
    this.showUploadCSV = true;
    this.totalUploadCSV = total;
    let time = 0;
    const requests = this.selectedProducts.map((item) =>
      this.itemService.removeItem(item, this.selectedLocationId),
    );
    try {
      const results = await Promise.all(requests);
      results.map(async () => {
        time += 1000;
        await this.sleep(time);
        this.completeUploadCSV++;
        if (this.totalUploadCSV === this.completeUploadCSV) {
          this.statusUploadCSV = 'Completed';
          setTimeout(() => {
            this.resetUploadValues();
            this.showUploadCSV = false;
            this.alertTitle = 'success';
            this.alertMessage = 'Items deleted successfully!';
            this.showConfirm = true;
            this.selectedProducts = [];
            this.getAllRecords();
            this.loading = false;
          }, 1000);
        }
      });
    } catch (e) {
      console.error(e.message);
      this.showUploadCSV = false;
      this.alertTitle = 'alert';
      this.alertMessage = 'Error while deleting products';
      this.showConfirm = true;
      this.loading = false;
    }
    this.positiveBtnText = 'Ok';
    this.negativeBtnText = undefined;
  }

  async setAllAsTopPicks() {
    this.selectedProducts = this.filteredItems.filter((i) => i.selected);
    if (!this.selectedProducts.length) {
      return;
    }
    const variants = [];

    this.selectedProducts.forEach((prod) => {
      variants.push(...this.getVariantsFromItem(prod));
    });

    this.loading = true;
    const total = this.selectedProducts.length;
    if (!total) {
      return;
    }
    try {
      await this.itemService.updateVariant(variants, this.selectedLocationId);
      this.selectedProducts = [];
      this.getAllRecords();
    } catch (error) {
      this.typeNotification = 'error';
      this.titleNotification = 'Error';
      this.messageNotification = "Couldn't update products";
      this.activateNotification = true;
    }
    this.loading = false;
  }

  async setAsTopPick(item: any) {
    this.loading = true;
    try {
      const variants = this.getVariantsFromItem(item);
      const result = await this.itemService.updateVariant(
        variants,
        this.selectedLocationId,
      );
      if (result) {
        this.selectedProducts = [];
        this.getAllRecords();
      }
    } catch (error) {
      this.activateNotification = true;
      this.typeNotification = 'error';
      this.titleNotification = 'Error';
      this.messageNotification = "Couldn't update product";
    }
    this.loading = false;
  }

  getVariantsFromItem(item: any): Array<VariantFlattend> {
    let variants = [];

    if (item.variants) {
      variants = item.variants.map((variant) => {
        const newVariant = {
          variantId: variant.id,
          variantName: variant.name,
          variantSku: variant.sku,
          itemName: item.name,
          itemManufacturer: item.manufacturer,
          itemId: item.id,
          variantUpc: variant.upc,
          variantLocations: [...variant.variantLocations],
          isTop: !item.isTop,
        };
        newVariant.variantLocations.forEach((v) => (v.isTop = !item.isTop));
        return newVariant;
      });
    }

    return variants;
  }

  pageEvent({ page }) {
    this.page = page;
    const outofStock = +this.filterByZeroStock;
    this.$store.commit('setProductsFilter', { page, outofStock });
    this.getAllRecords();
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    const outofStock = +this.filterByZeroStock;
    (this.page = 1),
      this.$store.commit('setProductsFilter', {
        page: 1,
        pageSize,
        outofStock,
      });
    this.getAllRecords();
  }
}
