
import Papa from 'papaparse';
import { get } from 'lodash';
import Item from '@/Interfaces/Item';
import Location from '../Interfaces/Location';
import { CsvHeader, CsvData, parseProductsImported } from '@/helper/CsvImport';
import LocationService from '@/services/LocationService';
import LocalStorageService from '@/services/LocalStorageService';
import { toRefs, PropType, reactive, onMounted } from 'vue';
import PreviewItemsImported from './PreviewItemsImported.vue';

export default {
  name: 'ImportProductsModal',
  emits: ['import-products'],
  components: {
    PreviewItemsImported,
  },
  props: {
    allLocations: {
      type: Array as PropType<Array<Location>>,
      required: true,
    },
  },
  methods: {
    positiveButton: function () {
      this.showConfirm = false;
    },
  },
  setup(props, context) {
    //-- Vars
    const localStorageService: LocalStorageService = new LocalStorageService();
    const locationService = new LocationService();

    const data = reactive({
      selected: localStorageService.getItem('primary_location_id'),
      allSelected: true,
      loading: false,
      csvData: [],
      locationError: false,
      fileError: false,
      activateNotification: false,
      showConfirm: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      csv: CsvHeader,
      itemWarningList: [],
      parseProducts: [],
      incorrectFile: false,
      warningtemplate: 'Item List - ',
      hasErrors: false,
      errors: [],
    });

    //-- Lifecycle
    onMounted(async () => {
      if (!data.selected) {
        data.selected = localStorageService.getItem('primary_location_id');
      }
      data.allSelected = false;
    });

    //-- Methods
    const isExcel = (file: any) => {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    };

    const checkFile = async (event: any) => {
      data.loading = true;
      const rawFile = event.target.files[0];
      data.itemWarningList = [];
      if (!isExcel(rawFile)) {
        data.activateNotification = true;
        data.typeNotification = 'error';
        data.titleNotification = 'error';
        data.messageNotification =
          'Only supports upload .xlsx, .xls, .csv suffix files';
        setTimeout(() => {
          data.activateNotification = false;
        }, 5000);
        data.loading = false;
        return false;
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const csv: string = reader.result as string;
          const csvData = get(
            Papa.parse(csv, { skipEmptyLines: true }),
            'data',
          );
          data.csvData = csvData;
        }
      };
      reader.readAsText(rawFile);

      const location = await selectedLocation();
      const parseProducts = await parseProductsImported(location, data.csvData);
      const products: Item[] = parseProducts.data;

      const flattenedVariants = products.reduce((acc, product) => {
        return [...acc, ...product[0].variants];
      }, []);

      flattenedVariants.forEach((variant) => {
        const price = parseFloat(variant.price);
        const wholesalePrice = parseFloat(variant.wholesalePrice);

        if (price < wholesalePrice) {
          data.itemWarningList.push(variant.name);
          data.showConfirm = true;
        }
      });
      data.parseProducts = products;
      data.incorrectFile = parseProducts.formatFileError;
      data.errors = parseProducts.errors;
      data.hasErrors = parseProducts.hasActiveErrors;
      data.loading = false;
    };

    const downloadCSVData = () => {
      let csv = CsvHeader;

      CsvData.forEach((row: any) => {
        csv += row.join(',');
        csv += '\n';
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'Sample_Import_Product.csv';
      anchor.click();
    };

    const selectedLocation = async () => {
      const locationsResponse = await locationService.getAllLocations();
      const locations = locationsResponse.data.locations;
      return locations.filter((l: any) => l.id === data.selected);
    };

    const importProducts = async () => {
      if (data.selected) {
        if (data.parseProducts.length === 0) {
          data.fileError = true;
        } else if (!data.selected) {
          data.locationError = true;
        } else {
          data.fileError = false;
          data.locationError = false;

          if (
            !data.hasErrors &&
            !data.fileError &&
            !data.locationError &&
            !data.incorrectFile &&
            data.parseProducts.length > 0
          ) {
            context.emit('import-products', data.parseProducts);
          }
        }
      } else {
        data.activateNotification = true;
        data.typeNotification = 'error';
        data.titleNotification = 'Error';
        data.messageNotification =
          'Location is mandatory. Please select location first.';
        setTimeout(() => {
          data.activateNotification = false;
        }, 5000);
      }
    };

    return {
      ...toRefs(data),
      checkFile,
      importProducts,
      downloadCSVData,
    };
  },
};
