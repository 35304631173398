
export default {
  name: 'IconBoxArrowInDown',
  props: {
    width: {
      default: 16,
    },
    height: {
      default: 16,
    },
  },
};
