import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-2 d-flex flex-column" }
const _hoisted_2 = { class: "progress w-100" }
const _hoisted_3 = { class: "text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "progress-bar bg-primary",
        role: "progressbar",
        style: { width: `${$setup.completedPercentage}%` },
        "aria-valuenow": $setup.completedPercentage,
        "aria-valuemin": 0,
        "aria-valuemax": 100
      }, null, 12, ["aria-valuenow"])
    ]),
    _createVNode("span", _hoisted_3, _toDisplayString($props.completedItems) + " / " + _toDisplayString($props.totalItems), 1)
  ]))
}