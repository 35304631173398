export default class GenericSortService {
  sortGeneric(a: any, b: any, isAsc: boolean) {
    if (a === null && b === null) return 0;

    if (isAsc) {
      if (a < b) return -1;
      if (a > b) return 1;
      if (b === null) return 1;
      if (a === null) return -1;
      else return 0;
    } else {
      if (a > b) return -1;
      if (a < b) return 1;
      if (b === null) return -1;
      if (a === null) return 1;
      else return 0;
    }
  }
}
