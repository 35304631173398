
import { computed } from 'vue';

export default {
  name: 'ProgressBar',
  props: {
    completedItems: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const completedPercentage = computed(() => {
      const total = props.totalItems === 0 ? 0 : 100 / props.totalItems;
      return props.completedItems * total;
    });

    return {
      completedPercentage,
    };
  },
};
