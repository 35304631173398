import Item from '@/Interfaces/Item';
import { ItemType } from '@/enums/ItemType';
import MarketPlace from '@/Interfaces/MarketPlace';

export default class ItemExportService {
  getCsvFilename(prefix: string) {
    const today = new Date(Date.now());
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${prefix}-${month}-${day}-${year}.csv`;
  }

  getCSVValues(items: Item[]): any[] {
    const lineItems: any[] = [];
    items.forEach((item) => {
      const locationIds = item.locations.map((location) => location.id);
      item.variants.forEach((variant) => {
        if (variant && variant.variantLocations) {
          variant.variantLocations.forEach((vl) => {
            if (vl.location) {
              if (vl.isActive && locationIds.includes(vl.location.id)) {
                const lineItem: any = {};
                lineItem['Item Name'] = item.name;
                lineItem['Item Description'] = item.description;
                lineItem['Category'] =
                  item.itemType === ItemType.Good
                    ? 'Good'
                    : item.itemType === ItemType.Service
                    ? 'Service'
                    : 'Bundle';
                lineItem['Variant Name'] = variant.name;
                lineItem['Variant SKU'] = variant.sku;
                lineItem['Variant Price'] = variant.price;
                lineItem['UPC'] = variant.upc;
                lineItem['Location'] = vl.location.name;
                lineItem['Current Stock'] = vl.currentStock;
                lineItem['Tracking Enabled'] = vl.isInventoryTrackingEnabled
                  ? 'Yes'
                  : 'No';
                lineItem['Low Stock Enabled'] = vl.isLowStock ? 'Yes' : 'No';
                lineItem['Low Stock Threshold'] = vl.stockThreshold;
                lineItems.push(lineItem);
              }
            }
          });
        }
      });
    });

    return lineItems;
  }

  getCSVValuesForMarketplace(items: MarketPlace[]): any[] {
    const lineItems: any[] = [];
    items.forEach((item) => {
      item.variants.forEach((variant) => {
        const lineItem: any = {};
        lineItem['Item Name'] = item.name;
        lineItem['Item Description'] = item.description;
        lineItem['Item Manufacturer'] = item.manufacturer;
        lineItem['Category'] =
          Number(item.itemType) === ItemType.Good ? 'Good' : 'Service';
        lineItem['Variant Name'] = variant.name;
        lineItem['Variant SKU'] = variant.sku;
        lineItem['UPC'] = variant.upc;
        lineItems.push(lineItem);
      });
    });
    return lineItems;
  }
}
